import React from "react";

import Layout from "../../../components/Layout/Layout";

const Article05112020 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">
              S&T AG: STRONG 3RD QUARTER - FORECAST RAISED
            </h1>
            <p className="bullet">
              Revenue growth in Q3 of 6.5% to EUR 297.7 million (previous year:
              EUR 279.4 million)
            </p>
            <p className="bullet">
              EBITDA increase in Q3 of around 16% to EUR 31.7 million (previous
              year: EUR 27.4 million)
            </p>
            <p className="bullet">
              Operating cash flow improved in Q3 by 40% to EUR 22.8 million
              (previous year: EUR 16.2 million)
            </p>
            <p className="bullet">
              2020 forecast increased from EUR 115 million EBITDA to at least
              EUR 122 million
            </p>
            <p>
              The technology group S&T AG (ISIN: AT0000A0E9W5, WKN: A0X9EJ,
              stock exchange symbol: SANT) continues to grow in Q3 – despite the
              global corona crisis and the accompanying economic crisis due to
              COVID-19. S&T is diversified with over 3,000 customers in a wide
              range of markets in more than 30 countries. Growth in the medical
              technology sector, for example, has more than compensated for the
              downturn in the aviation industry. Overall, S&T has therefore not
              been affected by the COVID-19 pandemic so far. Revenues in Q3
              increased by 6.5% compared to the same quarter of the previous
              year and by as much as 11% to EUR 297.7 million compared to Q2 of
              2020. EBITDA grew disproportionately by 16% compared to Q3 of 2019
              and by 18% compared to the previous quarter of 2020 to more than
              EUR 31.7 million. The measures implemented in the PEC programme
              led to a further improvement in operating cash flow, which
              increased by 40% to EUR 22.8 million in Q3 of 2020 (previous year:
              16.2 million). In the first nine months of the year, cash flow
              from operating activities was thus around EUR 45 million higher
              than in the previous year (EUR 4.5 million) at around EUR 50
              million. As in previous quarters, the “IoT Solutions Europe”
              sector generated the highest growth in revenue with 14.8% in Q3.
              The “IoT Solutions America” sector achieved an EBITDA margin of
              over 14.4% in Q3 2020 and therefore, restructuring has been
              successfully completed.
            </p>
            <p>
              Due to the good results, S&T plans to use half (previously 25%) of
              the net profit for 2020 for dividends and share buybacks. With EUR
              254 million in cash and a strong operating cash flow of EUR 50
              million after the first nine months of the current financial year,
              S&T is well equipped to meet its growth targets and acquisitions
              within the framework of Agenda 2023, as well as further share
              buybacks, from its own resources. The outlook is positive despite
              renewed global lockdowns. Despite the ongoing pandemic, the order
              book is full and at EUR 891 million is EUR 50 million better off
              than at the beginning of the year. In addition, following the
              approval of the antitrust authorities in September 2020, the
              Iskratel Group will bring S&T added strength from October, 1st
              2020.
            </p>
            <p>
              Hannes Niederhauser, CEO: "Based on these strong figures, we are
              raising our previous forecast for the 2020 financial year from EUR
              115 million EBITDA and EUR 1.15 billion in revenue to at least EUR
              122 million EBITDA and EUR 1.2 billion in revenue. Providing the
              effects of the new lockdowns are not too critical, these figures
              could be exceeded even further. We also reaffirm our medium-term
              target for 2023 of EUR 2 billion in revenue with an EBITDA of EUR
              220 million.”{" "}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Article05112020;
